import React, { Component } from "react"
import Layout from "../components/layout"
import styled, { keyframes } from "styled-components"
import "../components/layout.css"
import "./bookings.css"
import { getAllAppointments, addAppointment } from "../services/parse-bridge"
import { Calendar, momentLocalizer, Views } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import Modal from "react-modal"
import MainButton from "../components/main-button"
import { Colors } from "../colors/Colors"
import Loader from "react-loader-spinner"
import { MdMailOutline } from "react-icons/md"
import { FiPhone } from "react-icons/fi"

Modal.setAppElement("#___gatsby")
const localizer = momentLocalizer(moment)

class BookingsPage extends Component {
  state = {
    appointments: [],
    modalIsOpen: false,
    serviceType: "",
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    note: "",
    start: null,
    end: null,
    uploading: false,
    modalIndex: 0,
    selectedEvent: null,
  }

  componentDidMount() {
    getAllAppointments().then(res => {
      this.setState({ appointments: res })
    })
  }

  //MODAL
  openModal = e => {
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
    console.log("opened modal")
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  handleSelect = ({ start, end }) => {
    this.setState({ start, end, modalIndex: 0 }, () => {
      this.openModal()
    })
  }

  handleEventSelect = event => {
    this.setState({ modalIndex: 1, selectedEvent: event }, () => {
      this.openModal()
    })
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit(event) {
    const {
      fullName,
      serviceType,
      emailAddress,
      phoneNumber,
      note,
      start,
    } = this.state
    this.setState({ uploading: true })
    event.preventDefault()
    addAppointment(
      start,
      fullName,
      emailAddress,
      phoneNumber,
      note,
      serviceType,
      "Woodbrook"
    ).then(val => {
      this.setState({ uploading: false })
      if (val === true) {
        this.setState({ uploaded: true })
        //Add it to the local state
        this.setState({
          appointments: [
            ...this.state.appointments,
            {
              title: `${fullName} - ${serviceType}`,
              start,
              end: moment(start)
                .add(2, "hour")
                .toDate(),
            },
          ],
          modalIsOpen: false,
        })
      } else {
        alert("Something went wrong with your uplaod. Try again")
      }
    })
  }

  render() {
    const { selectedEvent } = this.state
    return (
      <>
        <Calendar
          selectable
          localizer={localizer}
          events={this.state.appointments}
          length={60}
          popup={true}
          step={30}
          min={moment()
            .startOf("day")
            .add(7, "hours")
            .toDate()}
          max={moment()
            .endOf("day")
            .subtract(5, "hours")
            .toDate()}
          defaultView={Views.WEEK}
          onSelectEvent={event => this.handleEventSelect(event)}
          onSelectSlot={this.handleSelect}
        />
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Subscribe Modal"
        >
          <Inner>
            {this.state.modalIndex === 0 && (
              <>
                <Logo src={require("../images/logo.png")} />
                <Title>Please enter the patient's information</Title>
                <FormContainer>
                  <Label>
                    Creating a new appointment for{" "}
                    {moment(this.state.start)
                      .format("ddd Do h:mm a")
                      .toString()}
                  </Label>
                  <Form noValidate onSubmit={this.handleSubmit}>
                    <Input
                      name="serviceType"
                      id="serviceType"
                      value={this.state.serviceType}
                      onChange={this.updateInput}
                      type="text"
                      placeholder="Procedure"
                    />
                    <Input
                      name="fullName"
                      id="fullName"
                      value={this.state.fullName}
                      onChange={this.updateInput}
                      type="text"
                      placeholder="Full name"
                    />
                    <Input
                      name="emailAddress"
                      id="emailAddress"
                      value={this.state.emailAddress}
                      onChange={this.updateInput}
                      type="email"
                      placeholder="Email address"
                    />
                    <Input
                      name="phoneNumber"
                      id="phoneNumber"
                      value={this.state.phoneNumber}
                      onChange={this.updateInput}
                      type="text"
                      placeholder="Phone Number"
                    />
                    <Textarea
                      name="note"
                      id="note"
                      value={this.state.note}
                      onChange={this.updateInput}
                      placeholder="Any notes?"
                    />
                    {this.state.uploading ? (
                      <Spinner>
                        <Loader
                          type="Oval"
                          color={Colors.gold}
                          height="50"
                          width="50"
                        />
                      </Spinner>
                    ) : (
                      <MainButton onClick={e => this.handleSubmit(e)}>
                        Create
                      </MainButton>
                    )}
                  </Form>
                </FormContainer>
              </>
            )}
            {this.state.modalIndex === 1 && (
              <>
                <DoneContainer>
                  <Title
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      marginBottom: "10px",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    Appointment Details
                  </Title>
                  <PatientInfo>
                    <Name>{selectedEvent.fullName}</Name>
                    <ContactItem>
                      <MdMailOutline color="#9599AB" size={22} />{" "}
                      <Contact>andelhusbands@gmail.com</Contact>
                    </ContactItem>
                    <ContactItem>
                      <FiPhone color="#9599AB" size={22} />{" "}
                      <Contact>868-718-8625</Contact>
                    </ContactItem>
                  </PatientInfo>
                  <hr />
                  <PatientInfo>
                    <SmallTitle>Date & Time</SmallTitle>
                    <Service>
                      {moment(selectedEvent.start).format(
                        "dddd Do MMMM, h:mm a"
                      )}
                    </Service>
                  </PatientInfo>
                  <hr />
                  <PatientInfo>
                    <SmallTitle>Service</SmallTitle>
                    <Service style={{ marginBottom: "10px" }}>
                      {selectedEvent.serviceType}
                    </Service>
                    <Tags>
                      {selectedEvent.confirmed && (
                        <ConfirmedTag>Confirmed</ConfirmedTag>
                      )}
                    </Tags>
                  </PatientInfo>
                  <hr />
                  {selectedEvent.note && (
                    <>
                      <SmallTitle>Additional Details</SmallTitle>
                      <Service
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        {selectedEvent.note}
                      </Service>
                    </>
                  )}
                  <ActionButtons>
                    <CancelButton>Cancel Booking</CancelButton>
                    <ConfirmButton>Confirm Appointment</ConfirmButton>
                  </ActionButtons>
                </DoneContainer>
              </>
            )}
          </Inner>
        </Modal>
      </>
      //   <FullCalendar
      //     defaultView="dayGridWeek"
      //     plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
      //     selectable={true}
      //     dateClick={info => console.log(info)}
      //     events={this.state.appointments}
      //     eventClick={info => console.log(info)}
      //     businessHours
      //     slotDuration={"01:00:00"}
      //   />
      //   </Layout>
    )
  }
}

export default BookingsPage

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}
const Spinner = styled.div`
  text-align: center;
  margin: 20px 0;
`
const Inner = styled.div`
  opacity: 1;
  /* animation: ${fadeIn} 1s ease-out forwards; */
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
`
const Logo = styled.img`
  max-width: 100px;
`
const Title = styled.h2`
  color: #2b2b2b;
  font-weight: 300;
  margin-bottom: 20px;
  font-size: 22px;
  font-family: "Articulat";
`
const FormContainer = styled.div``
const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Input = styled.input`
  border: none;
  border-bottom: 1px solid #d6d8e0;
  /* color: black; */
  font-size: 16px;
  margin-bottom: 20px;
  padding: 15px;
  font-family: "Articulat";

  ::placeholder {
    font-size: 15px;
  }

  :focus {
    outline: none;
    border-bottom: 2px solid ${Colors.gold};
  }
`
const Textarea = styled.textarea`
  border: none;
  border-bottom: 1px solid #d6d8e0;
  /* color: black; */
  margin-bottom: 20px;
  padding: 15px;
  font-family: "Articulat";

  ::placeholder {
    font-size: 15px;
  }

  :focus {
    outline: none;
  }
`
const Label = styled.label`
  font-family: "Articulat";
  font-size: 13px;
  margin-bottom: 20px;
  font-weight: 600;
  text-decoration: underline;
`
const DoneContainer = styled.div`
  text-align: left;
  width: 100%;
`
const Details = styled.p`
  font-size: 15px;
  font-family: "Articulat";
`
const ConfirmationContainer = styled.div`
  background-color: #edeeee;
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem 2rem;
`
const Service = styled.h2`
  font-family: "Articulat";
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: #1b1b1b;
`
const SmallTitle = styled.h3`
  color: #9397a9;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 12px;
  margin-bottom: 10px;
  font-family: "Articulat";
`
const Location = styled.address`
  font-family: "Articulat";
  font-style: inherit;
  font-weight: 500;
  line-height: 120%;
  color: #484848;
`
const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
`
const Name = styled.h1`
  font-weight: 600;
  margin: 40px 0 20px 0;
  font-size: 22px;
`
const ContactItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`
const Contact = styled.p`
  font-weight: 600;
  font-size: 15px;
  margin: 0 0 0 10px;
`
const Tags = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 10px 0;
`
const ConfirmedTag = styled.div`
  background-color: #ebf7f9;
  color: #6cc0cd;
  font-family: "Articulat";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0px;
  padding: 1px 7px;
  border-radius: 6px;
`
const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`
const CancelButton = styled.button`
  background-color: #eff1f6;
  color: #979cad;
  font-family: "Articulat";
  font-weight: 700;
  font-size: 15px;
  padding: 20px;
  margin: 5px;
  border: none;
  border-radius: 12px;
`
const ConfirmButton = styled.button`
  background-color: #6dc1ce;
  color: white;
  font-family: "Articulat";
  margin: 5px;
  font-weight: 700;
  font-size: 15px;
  padding: 20px;
  border: none;
  border-radius: 12px;
`
